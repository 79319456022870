import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(header-clay-court)/blog/NewsletterSignUp.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Footer/Footer.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/components/Header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/lib/router-events/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["BackgroundImage"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/BackgroundImage/BackgroundImage.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Container"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/Container/Container.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Group"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/Group/Group.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/Image/Image.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Paper"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/Paper/Paper.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/esm/components/Text/Text.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Title"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/Title/Title.mjs");
